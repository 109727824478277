import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`One year ago, I took the course “Full Stack for Front-End Engineers” at `}<a parentName="p" {...{
        "href": "https://frontendmasters.com"
      }}>{`Frontend Masters`}</a>{`.
I set up my own web server, bought a domain and started a web development blog using the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`Gatsby framework`}</a>{`.
12 months and 18 blog posts later, I would like to reflect on the past year and set some goals for 2023.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABwaFc7tJBP//EABcQAQEBAQAAAAAAAAAAAAAAAAEAEQL/2gAIAQEAAQUCOrdVJ6yG6xf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAQAGPwKkOiUyn//EABwQAAICAgMAAAAAAAAAAAAAAAERADEhUUFhof/aAAgBAQABPyElMhoCoqnGx5GdolUTuGOWQXcyZvoz/9oADAMBAAIAAwAAABDs7//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8Qcwh//8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxCHV//EAB0QAQACAgIDAAAAAAAAAAAAAAERIQAxQbFhcaH/2gAIAQEAAT8QVRLJNuWesWlAm0jLSvXnNGpWpw75xIMy/Kx6IEINphUM26TrP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "People celebrate and raise glasses of wine for a toast.",
          "title": "People celebrate and raise glasses of wine for a toast.",
          "src": "/static/55fbd0a65c88f5661a1bc3c59b3704d2/e5166/pexels-cottonbro-studio-raise-glasses.jpg",
          "srcSet": ["/static/55fbd0a65c88f5661a1bc3c59b3704d2/f93b5/pexels-cottonbro-studio-raise-glasses.jpg 300w", "/static/55fbd0a65c88f5661a1bc3c59b3704d2/b4294/pexels-cottonbro-studio-raise-glasses.jpg 600w", "/static/55fbd0a65c88f5661a1bc3c59b3704d2/e5166/pexels-cottonbro-studio-raise-glasses.jpg 1200w", "/static/55fbd0a65c88f5661a1bc3c59b3704d2/b17f8/pexels-cottonbro-studio-raise-glasses.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © cottonbro studio / pexels.com`}</em></p>
    <p>{`In general, I truly enjoy writing articles on a regular basis. I actually moved to Vienna in 2006 to study journalism. I've
also worked for some media organizations. But it wasn't the right fit and I returned to coding. So, it really feels good
to kind of combine my two passions.`}</p>
    <h2>{`What I've Learned this Year`}</h2>
    <ul>
      <li parentName="ul">{`First of all, how to set up my own web server, manage a domain, create an SSL certificate, and so on. It felt good to leave
my comfort zone and try something new!`}</li>
      <li parentName="ul">{`How to automate my build and deploy workflow with `}<a parentName="li" {...{
          "href": "https://github.com/features/actions"
        }}>{`GitHub Actions`}</a>{`.`}</li>
      <li parentName="ul">{`That I love to experiment with new features like the native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` HTML element or
the `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` CSS pseudo-class. It's a great challenge to explain the concept and functionality
in a succinct way and provide useful demo code.`}</li>
      <li parentName="ul">{`I became familiar with `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/RSS"
        }}>{`RSS`}</a>{` and learned how to provide my own RSS feed.
You can `}<a parentName="li" {...{
          "href": "https://www.oidaisdes.org/rss.xml"
        }}>{`subscribe here`}</a>{`.`}</li>
    </ul>
    <h2>{`My Favorite Blog Posts`}</h2>
    <p>{`It's hard to pick my favorites among the 18 blog posts I've written this year. Therefore, I simply picked three posts about
topics that I deem especially relevant and exciting:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.oidaisdes.org/common-aria-mistakes.en"
        }}>{`“Common ARIA mistakes and how to avoid them”`}</a>{`: It's great that more and more
companies realize the importance of accessibility. Unfortunately, a lot of them go overboard with ARIA, sometimes making their website
even less accessible. I hope my blog post helps developers to better understand ARIA and avoid bad practices.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.oidaisdes.org/cascade-layers-in-angular.en"
        }}>{`“Using CSS Cascade Layers in Angular”`}</a>{`: 2022 was a pretty great year for
CSS. Among the exciting new features adopted by modern browsers were CSS cascade layers. They're a powerful tool to structure your
styles and avoid pesky conflicts due to specificity or order of appearance.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.oidaisdes.org/lights-off-how-blind-users-navigate.en"
        }}>{`“Lights Off! Experience how Blind Users navigate the Web”`}</a>{`:
A few weeks ago, I gave a workshop on accessibility for a new client. Using my `}<a parentName="li" {...{
          "href": "https://alexlehner86.github.io/come-to-the-dark-side/home"
        }}>{`demo page`}</a>{`,
I turned everything black and used a screen reader to navigate the website. The participants were truly amazed. One of them
said: `}<ItalicText mdxType="ItalicText">{`“I didn't know that blind people can even use the internet!”`}</ItalicText>{` This taught me once more: People need to
experience the effects of disabilities to truly appreciate accessibility.`}</li>
    </ol>
    <h2>{`Goals and Expectations for 2023`}</h2>
    <p>{`As a web developer, I'm pretty excited for the new year. There's a lot of new features on the horizon that should make our lives easier.
As a human being, I just hope for the terrible war in Ukraine to end. And for humanity to tackle climate change head-on!`}</p>
    <p>{`Anyway, here's a list of goals for 2023 as well as topics I'd like to write about in the coming year:`}</p>
    <ul>
      <li parentName="ul">{`I want to turn my social media interactions into `}<a parentName="li" {...{
          "href": "https://www.freecodecamp.org/news/how-to-use-webmentions-with-gatsby-beginners-guide/"
        }}>{`Webmentions`}</a>{`
and display them on my blog.`}</li>
      <li parentName="ul">{`Flexbox or Grid? I want to deepen my knowledge about CSS layouts and experiment with new features like `}<a parentName="li" {...{
          "href": "https://ishadeed.com/article/learn-css-subgrid/"
        }}>{`CSS Subgrid`}</a>{`.`}</li>
      <li parentName="ul">{`I'd like to improve my skills as a Requirements Engineer and Software Architect and write about what I've learned.`}</li>
      <li parentName="ul">{`Attending more developer conferences and maybe `}<a parentName="li" {...{
          "href": "https://www.freecodecamp.org/news/complete-guide-to-giving-your-first-conference-talk/"
        }}>{`giving a talk some day`}</a>{`.
Hosting local events could be awesome too.`}</li>
      <li parentName="ul">{`Last but not least: Continue writing about accessibility.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      